.mobileSidebar-container {
  position: fixed;
  top: 90px;
  left: 0;
  height: 100%;
  z-index: 900;
  transition: transform 0.3s ease-in-out;
}

.mobileSidebar-toggle {
  position: fixed;
  top: 30px;
  left: 10px;
  z-index: 1001;
  background: transparent;
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
}

.mobileSidebar {
  background-color: #1f1f1f;
  color: #ffffff;
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}

.mobileSidebar-container.closed {
  transform: translateX(-100%);
}

.mobileSidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.mobileSidebar-item.active {
  color: #5500ff;
  font-weight: bold;
  border-radius: 10px;
}

.mobileSidebar-item:hover {
  background-color: #343434;
  color: #5500ff;
  border-radius: 10px;
}

.mobileSidebar-item i {
  margin-right: 10px;
  width: 20px;
  text-align: center;
}

@media (max-width: 868px) {
  .mobileSidebar {
    width: 200px;
  }
  .mobileSidebar-item {
    font-size: small;
  }
  .mobileSidebar-content {
    margin-bottom: 100px;
  }
}

@media (max-width: 780px) {
  .mobileSidebar-toggle {
    top: 30px;
    font-size: 20px;
  }
}

@media (max-width: 453px) {
  .mobileSidebar-toggle {
    top: 32px;
    font-size: 15px;
  }
}