/* AskQuickQuestion.css */

.ask-quick-question-container {
  background-color: #1f1f1f;
  color: #ffffff;
  padding: 20px;
  margin-top: 8%;
  margin-bottom: 2%;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ask-quick-question-title {
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.question-list {
  color: #ffffff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.question-card {
  background-color: #1f1f1f;
  color: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.question-card-closed {
  background-color: #1f1f1f;
  color: #ffffff;
}

.question-card-closed:hover {
  color: #ffffff;
  transform: scale(1.05);
}

.question-card-open {
  color: #ffffff;
}

.question-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-text {
  color: rgb(255, 255, 255);
  font-size: 1.125rem;
}

.question-arrow {
  color: rgb(255, 255, 255);
  font-size: 2rem;
}

.answer {
  color: #ffffff;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #323232;
  border-radius: 0.5rem;
  display: none;
}

.question-card-open .answer {
  display: block;
}

@media (max-width: 768px) {
  .ask-quick-question-container {
    margin-top: 21%;
  }
}

@media (max-width: 1031px) {
  .ask-quick-question-container {
    margin-top: 10%;
  }
}

@media (max-width: 910px) {
  .ask-quick-question-container {
    margin-top: 12%;
  }
}

@media (max-width: 790px) {
  .ask-quick-question-container {
    margin-top: 14%;
  }
}

@media (max-width: 670px) {
  .ask-quick-question-container {
    margin-top: 16%;
  }
}

@media (max-width: 570px) {
  .ask-quick-question-container {
    margin-top: 18%;
  }
}

@media (max-width: 470px) {
  .ask-quick-question-container {
    margin-top: 20%;
  }
}

@media (max-width: 370px) {
  .ask-quick-question-container {
    margin-top: 22%;
  }
}