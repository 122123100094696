.all-time-stats {
  background-color: #404040;
  color: #ffffff;
  max-width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.all-time-stats h2 {
  margin-bottom: 20px;
  color: #ffffff;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.stat-card {
  background-color: #343434;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 0px  #252525;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.stat-card:hover {
  transform: scale(1.05);
}

.stat-icon {
  background-color: #c0025e;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-right: 15px;
}

.stat-info {
  flex-grow: 1;
}

.stat-info h3 {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
}

@media (max-width: 900px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width:768px) {
  .stats-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
