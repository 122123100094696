/* styles/AffiliateSections.css */

.affiliate-sections {
  background-color: #404040;
  backdrop-filter: blur(7.5px);
  padding: 1rem;
  border-radius: 30px;
  color: #ffffff;
  position: relative;
  height: 300px;
  margin-bottom: 20px;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.referral-url-section {
  padding: 20px;
  color: #fff;
}

.referral-url-section p {
  margin-bottom: 10px;
  color: #ffffff;
}

.referral-url-container {
  background-color: transparent;
  display: flex;
  align-items: center;
}

.referral-url-container input {
  background-color: transparent;
  color: #fff;
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid #ffffff;
  border-radius: 30px;
  font-size: 16px;
}

.referral-url-container button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
}

.referral-url-container button:hover {
  background-color: transparent;
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.floating-logo-container {
  position: absolute;
  bottom: -10px;
}

.floating-logo-container.left {
  left: 20px;
}

.floating-logo-container.right {
  right: 20px;
}

.floating-logo {
  width: 80px;
  height: auto;
  animation: float 3s ease-in-out infinite;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.1));
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.floating-logo-container.right .floating-logo {
  animation-delay: 1.5s;
}

@media (max-width: 768px) {
  .affiliate-sections {
    margin-top: 100px;
  }

  .floating-logo {
    width: 60px;
  }

  .floating-logo-container.left {
    left: 10px;
  }

  .floating-logo-container.right {
    right: 10px;
  }
}