/* BennerAds.css */

.product-promotion-form {
    background: linear-gradient(45deg, #a735a4, #2e2ca7) !important;
    color: rgb(255, 255, 255);
}

.image-ticker {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: transparent;
    padding: 10px 0;
}

.ticker-content {
    display: flex;
}

.images-item {
    flex: 0 0 auto;
    margin: 0 10px;
}

.images-logo {
    height: 90px;
    width: auto;
    transition: transform 0.3s ease-in-out;
}

.images-logo:hover {
    transform: scale(1.1);
}

@keyframes scrollTicker {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}
