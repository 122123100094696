.dash {
  margin-top: 10%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
}

.dash-grid-span1 {
  grid-column: span 1;
}

.dash-grid-span1 {
  grid-column: span 2;
}

.profile-info {
  margin-top: 170px;
  margin-bottom: 50px;
  width: 330px;
}

.profile-info-header {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0px;
  margin-left: 120px;
}

.edit-button {
  background-color: transparent;
  color: #ffffff;
  margin-top: 20px;
  margin-left: 20px;
  border: 1px solid #6e6e6e;
  border-radius: 2px;
  width: 50px;
  cursor: pointer;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}

.nrg-graf {
  display: block;
  width: 250px;
  height: 100px;
  margin-left: 100px;
  margin: auto;
}

.nft-exp {
  height: 230px;
  width: 150px;
  margin-top: 80px;
  margin-left: 70px;
  color: #5BE12C;
  color: #EA4228;
}

.nrg-level-text {
  color: #ffffff;
  margin-top: -30px;
  text-align: center;
}

.value-text {
  display: none;
}

.copy-referral-text {
  margin: auto;
  margin-left: 0px;
}

.copy-referral-button {
  width: 275px;
}

.gauge-component {
  height: 80px;
  width: 300px;
  margin-bottom: 100px;
  margin-left: 20px;
}

.button-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.dash-card-details {
  background-color: transparent;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 80%;
  margin-left: 30px;
}

.crypto-ticker {
  overflow: hidden;
  white-space: nowrap;
  background-color: transparent;
  padding: 10px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ticker-content {
  display: inline-flex;
  animation: scroll 20s linear infinite;
}

.ticker-content:hover {
  animation-play-state: paused;
}

.crypto-item {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
  padding: 0 10px;
}

.crypto-logo {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.crypto-name {
  color: #ffffff;
  margin: 0 5px;
}

.crypto-price {
  color: #ffffff;
  margin: 0 5px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.staking-container {
  background-color: transparent;
  padding: 20px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.stats-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stat-item {
  display: flex;
  flex-direction: column;
}

.stat-label {
  color: #8a8a8a;
  font-size: 14px;
  margin-bottom: 5px;
}

.last-label {
  margin-right: 10px;
}

.lock-amount {
  display: flex;
  justify-content: space-between;
  color: #8a8a8a;
  font-size: 16px;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 24px;
  font-weight: 500;
}

.locked-amount {
  color: #ff69528a;
}

.question-icon {
  margin: auto;
  cursor: pointer;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -110px; /* Adjust width here */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.know-more-text {
  color: #23ffa0;
}

.button-row {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.action-button {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  background-color: #6c2bd9;
  transition: background-color 0.2s;
}

.action-button:hover {
  background-color: #5620b0;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.metric-card {
  background-color: #242424;
  border-radius: 8px;
  padding: 20px;
}

.metric-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric-value {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

.metric-label {
  color: #8a8a8a;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.dash-icon {
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.dash-icon {
  color: #ff0088;
}

.white-icon {
  color: #ffffff;
}

.modul-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(2px);
}

.modul-container {
  background-color: #1f1f1f;
  border-radius: 4px;
  height: 90%;
  width: 90%;
  border: 1px solid #7c7c7c;
  overflow-y: auto;
  position: relative;
  padding: 20px;
}

.modul-container::-webkit-scrollbar {
  width: 8px;
}

.modul-container::-webkit-scrollbar-track {
  border-radius: 4px;
}

.modul-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

@keyframes modulFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modul-container {
  animation: modulFadeIn 0.2s ease-out;
}

.modul-close-button {
  background-color: #ff2ea1;
  color: #ffffff;
  position: absolute;
  border-radius: 4px;
  top: 5px;
  right: 10px;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
  padding: 5px 10px;
  z-index: 999;
}

@media screen and (max-width: 1850px) {
  .copy-referral-button {
    width: 310px;
  }
}

@media screen and (max-width: 1850px) {
  .copy-referral-button {
    width: 310px;
  }
}

@media screen and (max-width: 1750px) {
  .copy-referral-button {
    width: 290px;
  }
}

@media screen and (max-width: 1600px) {
  .copy-referral-button {
    width: 260px;
  }
}

@media screen and (max-width: 1520px) {
  .copy-referral-button {
    width: 250px;
  }
}

@media screen and (max-width: 1450px) {
  .copy-referral-button {
    width: 230px;
  }
}

@media screen and (max-width: 1380px) {
  .copy-referral-button {
    width: 210px;
  }
}

@media screen and (max-width: 1300px) {
  .copy-referral-button {
    width: 190px;
  }
}

@media screen and (max-width: 1220px) {
  .copy-referral-button {
    width: 170px;
  }
}

@media screen and (max-width: 1180px) {
  .dash {
    margin-top: 12%;
  }
  .nft-exp {
    height: 210px;
    width: 130px;
  }
  .copy-referral-button {
    width: 130px;
  }
}

@media screen and (max-width: 1080px) {
  .dash {
    margin-top: 14%;
  }
  .gauge-component {
    height: 60px;
    width: 230px;
    margin-bottom: 90px;
    margin-left: 17px;
  }
  .profile-info-header {
    margin-left: 100px;
  }
  .dash-card-details {
    width: 65%;
  }
  .nft-exp {
    height: 200px;
    width: 110px;
    margin-top: 70px;
    margin-left: 60px;
  }
}

@media screen and (max-width: 1030px) {
  .dash {
    margin-top: 10%;
  }
  .dash-grid-span1,
  .dash-grid-span2 {
    margin-top: 10%;
  }
  .copy-referral-button {
    width: 190px;
  }
}

@media screen and (max-width: 970px) {
  .metrics-grid {
    grid-template-columns: 1fr;
  }
  .dash-grid-span1,
  .dash-grid-span2 {
    margin-top: 10%;
  }
  .copy-referral-button {
    width: 170px;
  }
}

@media (max-width: 910px) {
  .dash {
    margin-top: 12%;
  }
  .copy-referral-button {
    width: 140px;
  }
}

@media (max-width: 830px) {
  .copy-referral-button {
    width: 120px;
  }
}

@media (max-width: 790px) {
  .dash {
    margin-top: 14%;
  }
  .nft-exp {
    margin-left: 220px;
  }
  .copy-referral-button {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .dash {
    grid-template-columns: repeat(1, 1fr);
  }
  .stat-item {
    align-items: center;
  }
  .dash-grid-span1 {
    margin-top: 7%;
  }
  .dash-grid-span2 {
    margin-top: 20px;
  }
  .gauge-component {
    height: 120px;
    width: 550px;
    margin-left: 100px;
  }
  .profile-image {
    margin-top: 150px;
    margin-left: 250px;
  }
  .edit-button {
    margin-left: 250px;
  }
  .dash-card-details {
    width: 100% !important;
    margin-left: 180px !important;
  }
  .nft-exp {
    height: 400px;
    width: 210px;
    margin-top: 250px;
    margin-left: 250px;
    margin-bottom: 30px;
  }
  .crypto-ticker {
    padding: 5px 0;
  }
  .crypto-item {
    margin-right: 20px;
    padding: 0 5px;
  }  
  .crypto-logo {
    width: 20px;
    height: 20px;
  }
  .modul-container {
    width: 95%;
    padding: 15px;
    margin: 10px;
  }
  .copy-referral-button {
    width: 200px;
  }
}

@media (max-width: 670px) {
  .dash {
    margin-top: 16%;
  }
  .gauge-component {
    margin-left: 70px;
  }
  .profile-image {
    margin-left: 230px;
  }
  .edit-button {
    margin-left: 230px;
  }
  .dash-card-details {
    margin-left: 160px !important;
  }
  .nft-exp {
    margin-left: 200px;
  }
  .copy-referral-button {
    width: 190px;
  }
}

@media (max-width: 630px) {
  .gauge-component {
    margin-left: 60px;
  }
  .profile-image {
    margin-left: 210px;
  }
  .edit-button {
    margin-left: 220px;
  }
  .dash-card-details {
    margin-left: 150px !important;
  }
  .nft-exp {
    margin-left: 190px;
  }
}

@media (max-width: 610px) {
  .gauge-component {
    height: 110px;
    width: 530px;
    margin-left: 50px;
  }
  .profile-image {
    margin-left: 200px;
  }
  .edit-button {
    margin-left: 210px;
  }
  .dash-card-details {
    margin-left: 140px !important;
  }
  .nft-exp {
    height: 370px;
    width: 190px;
    margin-top: 240px;
    margin-left: 230px;
  }
  .copy-referral-button {
    width: 180px;
  }
}

@media (max-width: 570px) {
  .dash {
    margin-top: 18%;
  }
  .gauge-component {
    height: 80px;
    width: 460px;
    margin-left: 40px;
  }
  .profile-image {
    margin-left: 150px;
  }
  .edit-button {
    margin-left: 160px;
  }
  .dash-card-details {
    margin-left: 90px !important;
  }
  .nft-exp {
    height: 320px;
    width: 150px;
    margin-top: 220px;
    margin-left: 200px;
  }
  .copy-referral-button {
    width: 170px;
  }
}

@media (max-width: 500px) {
  .gauge-component {
    height: 60px;
    width: 400px;
    margin-left: 30px;
  }
  .profile-image {
    margin-left: 100px;
  }
  .edit-button {
    margin-left: 110px;
  }
  .dash-card-details {
    margin-left: 40px !important;
  }
  .nft-exp {
    height: 300px;
    width: 130px;
    margin-top: 190px;
    margin-left: 150px;
  }
  .copy-referral-button {
    width: 160px;
  }
}

@media (max-width: 470px) {
  .dash {
    margin-top: 20%;
  }
  .copy-referral-button {
    width: 150px;
  }
}

@media (max-width: 430px) {
  .gauge-component {
    height: 50px;
    width: 370px;
    margin-left: 25px;
  }
  .profile-image {
    margin-top: 100px;
    margin-left: 80px;
  }
  .dash-card-details {
    width: 90% !important;
  }
  .nft-exp {
    height: 300px;
    width: 130px;
    margin-top: 190px;
    margin-left: 120px;
  }
  .copy-referral-button {
    width: 120px;
  }
}

@media (max-width: 400px) {
  .gauge-component {
    height: 45px;
    width: 330px;
    margin-left: 27px;
  }
  .profile-image {
    margin-top: 50px;
    margin-left: 60px;
  }
  .edit-button {
    margin-left: 75px;
  }
  .dash-card-details {
    margin-left: 30px !important;
  }
  .nft-exp {
    height: 300px;
    width: 150px;
    margin-top: 170px;
    margin-left: 100px;
  }
  .copy-referral-button {
    width: 100px;
  }
}

@media (max-width: 370px) {
  .dash {
    margin-top: 22%;
  }
  .gauge-component {
    height: 40px;
    width: 300px;
    margin-left: 25px;
  }
  .profile-image {
    margin-top: 30px;
    margin-left: 40px;
  }
  .edit-button {
    margin-left: 56px;
  }
  .profile-info-header {
    width: 160px;
  }
  .profile-info {
    width: 330px;
  }
  .dash-card-details {
    margin-left: 35px !important;
    width: 80% !important;
  }
  .nft-exp {
    height: 280px;
    width: 130px;
    margin-top: 130px;
    margin-left: 80px;
  }
}

@media (max-width: 330px) {
  .profile-info {
    width: 320px;
  }
}

@media (max-width: 310px) {
  .profile-info {
    width: 300px;
  }
}