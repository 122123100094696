.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #242424;
    color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 15px;
    max-width: 480px;
    width: 100%;
    color: white;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 200px;
  height: 200px;
}

.loading-spinner h6 {
    text-align: center;
    font-weight: bold;
}

.loading-spinner img {
  width: 100%;
  height: 100%;
}

.custom-alert.hide {
    opacity: 0;
}

.modal-content h2 {
    margin-bottom: 20px;
    font-size: 18px;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    font-weight: bold;
    color: #fff !important;
}

.close-modul-button {
    background-color: #ff2ea1;
    cursor: pointer;
    border-radius: 4px;
    width: 20px;
    height: 23px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: bold;
}

.input-group input {
    color: #000;
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    font-size: 16px;
    font-weight: bold;
}

.max-btn {
    padding: 10px 15px;
    border: none;
    border-left: none;
    background-color: #7d49dd;
    color: #ffffff;
    border-radius: 0 5px 5px 0;
    font-size: 17px;
    cursor: pointer;
    font-weight: bold;
}

.wallet-info {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 20px;
    font-weight: bold;
}

.user-icon {
    width: 80px;
    height: 80px;
    background-color: transparent;
    border-radius: 50%;
    margin: 0 auto 15px;
    display: block;
}

.ref-code,
.tokens {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

.daily-withdraw-btn {
    background-color: #7d49dd;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin: 15px 0;
    cursor: pointer;
    font-weight: bold;
}

.withdraw-info {
    color: #e5f04e;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
}

.daily-deposit-btn,
.daily-withdraw-btn {
    background-color: #7d49dd;
    transition: transform .2s;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin: 15px 0;
    cursor: pointer;
    font-weight: bold;
}

.copy-btn {
    background-color: #7d49dd;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 8px;
    min-width: 70px;
    cursor: pointer;
    font-weight: bold;
}

.days-remaining {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.deposit-info {
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
}

.gasfee-info {
    color: yellow;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
}