.withdraw-claim-btn {
    display: flex;
    justify-content: space-between;
}

.claim-btn,
.reinvest-btn {
    background-color: #7d49dd;
    transition: transform .2s;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    min-width: 70px;
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
}

.claim-btn {
    margin-right: 10px;
}

.tokens,
.ref-code {
    flex-grow: 1;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.file-input {
    margin: auto;
  padding: 10px;
  font-size: 16px;
}

.reward {
    color: #2dea72;
}

.referral-link {
    margin-top: 6px;
}

.pending-reward {
    margin-top: 10px;
}

.minimum-withdraw {
    color: #e5f04e;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
}