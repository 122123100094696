.rewards-cards {
  background-color: transparent;
  color: #ffffff;
  padding: 20px;
  margin-top: 9%;
  margin-bottom: 20px;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rewards-cards h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 2rem;
}

.rewards-container {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.rewards-card {
  background-color: #1e1740;
  color: #ffffff;
  padding: 1.5rem;
  border-radius: 30px;
  width: 22%;
  margin-right: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.rewards-card h3 {
  background-color: transparent;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  margin: 0;
  margin-bottom: 1rem;
  font-weight: bold;
}

.rewards-price {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.rewards {
  color: #ffffff;
  font-weight: bold;
  padding-top: 0.5rem;
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.rewards-features {
  color: #fff;
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.active-feature {
  color: #ffffff;
}

.rewards-features li {
  color: #fff;
  padding: 0.5rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}

.rewards-checkmark {
  color: #ffffff;
  font-size: 1.2rem;
}

.video-popup-overlay {
  background-color: #404040;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 80%;
  max-width: 800px;
}

.video-popup
.popup-close-btn {
  background-color: #ff197d;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .rewards-container {
    flex-wrap: wrap;
  }
  
  .rewards-card {
    flex-basis: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .rewards-card {
    flex-basis: 100%;
  }

  .rewards-cards {
    margin-top: 22%;
  }
}

@media (max-width: 1031px) {
  .rewards-cards {
    margin-top: 10%;
  }
}

@media (max-width: 910px) {
  .rewards-cards {
    margin-top: 12%;
  }
}

@media (max-width: 790px) {
  .rewards-cards {
    margin-top: 14%;
  }
}

@media (max-width: 670px) {
  .rewards-cards {
    margin-top: 16%;
  }
}

@media (max-width: 570px) {
  .rewards-cards {
    margin-top: 18%;
  }
}

@media (max-width: 470px) {
  .rewards-cards {
    margin-top: 20%;
  }
}

@media (max-width: 370px) {
  .rewards-cards {
    margin-top: 22%;
  }
}