/* VIPMembershipCards.css */
* {
  box-sizing: border-box;
}

.vip-membership-cards {
  background-color: transparent;
  color: #ffffff;
  max-width: 100%;
  padding: 20px;
  margin-top: 8%;
  margin-bottom: 20px;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: rgb(0, 0, 0);
}

.video-popup-container {
  padding: 57% 0 0 0;
  position: relative;
}

.popup-video-bacground {
  position: relative;
  background-image: url(../assets/background-loading.gif);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.popup-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.watch-tutorial {
  display: flex;
  padding: 8px 16px;
  background-color: #5620b0;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px auto;
  margin-right: 10px;
}

.vip-membership-cards h2 {
  color: #ffffff;
}

.vip-membership-cards-modul h2 {
  color: #ffffff;
}

.join-now-btn {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #632de9;
  transition: transform .2s;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.join-now-btn:hover {
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.cards-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.card-title-price {
  height: 120px;
}

.card {
  background-color: #1f1f1f;
  color: #ffffff;
  padding: 1.5rem;
  border: 1px solid #fff;
  width: 99%;
  margin-right: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.card-detail p {
  font-weight: bold;
  color: #ffffff;
}

.p1 {
  margin-top: 200px;
  color: #fff;
}

.card:nth-child(even) {
  background-color: #1e1740;
}

.card h3 {
  color: #fff;
  margin: 0;
  margin-bottom: 1rem;
}

.price {
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  margin: 1rem 0;
}

.view-cards-btn {
  background-color: #632de9;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 18px;
}

.view-cards-btn:hover {
  transform: scale(1.05);
}

.features {
  color: #ffffff;
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
  width: 99%;
  flex-grow: 1;
}

.features li {
  color: #ffffff;
  padding: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.486);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 12px;
}

.checkmark {
  color: #15ff04;
  font-size: 1.2rem;
}

.card-details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 9999;
}

.card-details {
  background-color: #1f1f1f !important;
  color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  position: relative;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease; /* Optional: Fade-in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .card-details {
    padding: 1rem;
    width: 95%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .card-details {
    padding: 0.5rem;
  }
}

.header-image {
  height: 350px;
  width: 95%;
}

.card-details h2 {
  color: #ffffff;
  margin-top: 0;
}

.card-details-content {
  display: flex;
}

.card-image {
  flex: 1;
  margin-right: 2rem;
}

.placeholder-image {
  color: white;
  height: 450px;
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.placeholder-image video {
  background-color: transparent;
  border-radius: 10px;
}

.card-info {
  color: #ffffff;
  flex: 1;
}

.card-info h3 {
  color: #ffffff;
}

.card-price {
  color: #ffffff;
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0;
}

.card-input {
  background-color: transparent;
  color: #ffffff;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  color: #ffffff;
}

.feature-list li {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c0c0c0;
  margin-left: 0px;
}

.included {
  color: #0dff00;
  font-weight: bold;
  font-size: 1.5rem;
}

.not-included {
  color: red;
  font-weight: bold;
  font-size: 1.5rem;
}

.close-btn {
  background-color: #ff2ea1 !important;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  border-radius: 8px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #ffffff;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
}

.loading-spinner img {
  width: 100%;
  height: 100%;
}

.price-number {
  justify-content: space-between;
}

.price-number input {
  color: #ffffff;
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.features li:last-child {
  color: #ffffff;
  border-bottom: none;
}

.active-feature {
  color: rgb(255, 255, 255);
  width: 100%;
}

.inactive-feature {
  color: rgb(255, 255, 255);
  width: 100%;
}

.cross {
  color: #ff0000;
  font-size: 18px;
  font-weight: bold;
}

.vip-membership-header {
  margin-top: 10px;
}


@media screen and (max-width: 1330px) {
  .view-cards-btn {
    font-size: 13px;
  }
}

@media screen and (max-width: 1100px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

@media (max-width: 1031px) {
  .vip-membership-cards {
    margin-top: 10%;
  }
}

@media (max-width: 910px) {
  .vip-membership-cards {
    margin-top: 12%;
  }
}

@media (max-width: 790px) {
  .vip-membership-cards {
    margin-top: 14%;
  }
}

@media screen and (max-width: 768px) {
  .vip-membership-cards {
    margin-top: 22%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 15px;
  }

  .cards-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 7px;
  }

  .card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .card-details-content {
    display: flex;
    flex-direction: column;
  }

  .card-image {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .placeholder-image {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }

  .card-info {
    width: 100%;
  }

  .price-number {
    flex-direction: column;
    align-items: flex-start;
  }

  .card-input {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }

  .card-details {
    padding: 1rem;
    width: 95%;
    height: 90vh;
  }

  .join-now-btn {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    position: static;
  }
}

@media (max-width: 570px) {
  .vip-membership-cards {
    margin-top: 18%;
  }
  .header-image {
    display: none;
  }
  .vip-membership-header {
    margin-top: 15px;
    font-size: 20px;
  }
}

@media (max-width: 470px) {
  .vip-membership-cards {
    margin-top: 20px;
    margin-top: 20%;
  }
  .cards-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 420px) {  
  .vip-membership-header {
    margin-top: 22px;
    font-size: 15px;
  }
  .card-details h2 {
    font-size: 18px;
  }
 }

@media (max-width: 370px) {
  .vip-membership-cards {
    margin-top: 22%;
  }
  .card-details h2 {
    font-size: 16px;
  }
}

 @media (max-width: 355px) {  
  .vip-membership-header {
    margin-top: 25px;
    font-size: 12px;
  }
  .card-details h2 {
    font-size: 14px;
  }
 }

 @media (max-width: 307px) { 
  .vip-membership-cards {
    margin-top: 25%;
  } 
  .vip-membership-header {
    font-size: 11px;
  }
 }