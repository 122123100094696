.dialog {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.mb-2,
.mb-4 {
  color: #000000;
}

.dialog-open {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  position: relative;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.gift-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gift-icon {
  width: 128px;  /* Explicitly set width */
  height: 128px; /* Explicitly set height */
  transition: transform 0.5s ease;
}

.gift-icon:hover {
  transform: scale(1.1);
}

.claim-button {
  background-color: #ff4081;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.claim-button:hover {
  background-color: #ff1a4d;
}

.scale-110 {
  transform: scale(1.1);
}

.scale-95 {
  transform: scale(0.95);
}

.rotate-12 {
  transform: rotate(12deg);
}