.profile-container {
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.upload-container {
    background-color: #252525;
    border-radius: 4px;
    border: 1px solid #4b4b4b;
}

.upload-button-area {
    margin: 60px;
}

.lavel-cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #ffffff;
  margin-right: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.file-uploader {
  width: 100%;
  max-width: 28rem;
  padding: 1.5rem;
}

.file-uploader > * + * {
  margin-top: 1rem;
}

/* Info Box Styles */
.info-box {
  background-color: #EFF6FF;
  border: 1px solid #BFDBFE;
  color: #1D4ED8;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  position: relative;
}

.info-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.info-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.file-type {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.category {
  font-weight: 500;
}

/* Upload Area Styles */
.upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border: 2px dashed #D1D5DB;
  border-radius: 0.5rem;
  transition: border-color 0.2s;
}

.upload-area:hover {
  border-color: #9CA3AF;
}

.upload-icon {
  width: 3rem;
  height: 3rem;
  color: #9CA3AF;
  margin-bottom: 1rem;
}

.file-input {
  display: none;
}

.user-name-input {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #adadad;
    border-radius: 4px;
    height: 30px;
    margin-top: 20px;
}

.select-button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid #fff;
  color: white;
  border-radius: 0.375rem;
  cursor: pointer;
}

.select-button:hover {
  background-color: #3a3a3a;
}

.label-input {
    display: grid;
}

.user-name {
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 0px;
}

.selected-file {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #8d8d8d;
}

/* Upload Button Styles */
.upload-button {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #3B82F6;
  color: white;
  border-radius: 0.375rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-icon {
  width: 1rem;
  height: 1rem;
}

/* Upload Progress Styles */
.upload-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #3B82F6;
}

.spinner {
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Error Message Styles */
.error-message {
  background-color: #FEE2E2;
  border: 1px solid #FECACA;
  color: #B91C1C;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  position: relative;
}

/* Success Message Styles */
.success-message {
  background-color: #DCFCE7;
  border: 1px solid #BBF7D0;
  color: #15803D;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  position: relative;
}

/* Shared Message Styles */
.message-header {
  display: flex;
  align-items: center;
}

.message-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.message-content {
  display: block;
  margin-left: 1.5rem;
}

/* File Link Styles */
.file-link {
  color: #3B82F6;
}

.file-link:hover {
  text-decoration: underline;
}