.decentralized-apps {
  background-color: transparent;
  color: #ffffff;
  font-family: Arial, sans-serif;
  text-align: center;
}

.decentralized-apps h1 {
  color: #ffffff;
  text-align: left;
}

.app-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 7px;
  margin-bottom: 7px;
}

.app-card {
  background-color: #404040;
  color: #ffffff;
  box-shadow: 0 3px 0px #595959;
  padding: 20px;
  border-radius: 15px;
  margin-top: 7px;
  margin-bottom: 7px;
}

.app-card img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.h3-p {
  height: 150px;
}

.app-card h2 {
  margin-top: 10px;
  font-size: 18px;
  color: #ffffff;
}

.app-card p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.4;
}

.comming-soon-button {
  padding: 12px;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  background-color: #6c2bd9;
  transition: background-color 0.2s;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 1024px) {
  .app-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .decentralized-apps {
    margin-left: 1%;
    margin-right: 1%;
    padding: 15px;
  }

  .app-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .app-card {
    padding: 15px;
  }

  .app-card h2 {
    font-size: 16px;
  }

  .app-card p {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .app-grid {
    grid-template-columns: 1fr;
  }

  .decentralized-apps h1 {
    font-size: 24px;
  }

  .app-card img {
    max-height: 150px;
  }
}