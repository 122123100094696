.milestone-cards-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.card-video {
    margin: auto;
}

@media screen and (max-width: 1100px) {
    .milestone-cards-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }
}

@media screen and (max-width: 768px) {
    .milestone-cards-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 7px;
    }
}

@media (max-width: 505px) {
    .milestone-cards-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 350px) {
    .milestone-cards-container {
        max-width: 300px;
    }
}