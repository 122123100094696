.referral-tree-container {
  background-color: #1f1f1f;
  color: #ffffff;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  margin-top: 100px;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
}

.referral-tree-container2 {
  background-color: #1f1f1f;
  color: #ffffff;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  margin-top: 100px;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
}

.referral-video-container {
  background-color: #1f1f1f;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin-top: 10%;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
}

.milestone-heater-text {
  text-align: center !important;
  margin-top: 0px;
}

.reward-amount {
  color: #0dff00;
  font-size: 20px;
}

.mileston-container {
  flex: 1;
  border: 1px solid #3a3a3a;
  margin: 10px;
}

.mileston-container-top {
  display: flex;
}

.right-container {
  flex: 1;
  margin-right: 2rem;
}

.left-container {
  flex: 1;
  margin-right: auto;
}

.left-container li {
  margin-left: 5px;
  width: 100%;
}

.left-container h2 {
  text-align: center;
}

.lovelace-title {
  color: #1E90FF !important;
}

.tesla-title {
  color: #32CD32 !important;
}

.turing-title {
  color: #FFD700 !important;
}

.jobs-title {
  color: #FF8C00 !important;
}

.satoshi-title {
  color: #FF4500 !important;
}

.mileston-image {
  flex: 1;
  height: 230px;
  width: 99%;
  border-radius: 8px;
}

.mileston-video {
  display: flex;
  height: 90%;
  width: 95%;
  border-radius: 8px;
  margin: 5px auto;
}

.gauge-referral {
  width: 170px;
  height: auto;
}

.referral-video {
  color: #ffffff;
  text-align: center;
  max-width: 100%;
  max-height: 400px;
}

.wattuto-heder-container {
  display: flex !important;
  justify-content: space-between !important;
}

.referral-node {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.node-content {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.percent {
  color: #3a3a3a;
  font-size: 15px;
}

.target-amount {
  color: #FFD700;
  color: #4e1500;
  color: #0dff00;
}

.avatar {
  color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.node-details {
  background: #b4007b;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  width: 150px;
}

.node-details .address {
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 12px;
}

.node-details div {
  margin: 0;
}

.children {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  max-width: 800px;
  font-size: 14px;
}

.back-button {
  position: absolute;
  top: 120px;
  left: 220px;
  padding: 10px 20px;
  background-color: #1b02c0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
}

.level-0>.children {
  position: relative;
  margin-top: 30px;
}

.level-0>.children::before {
  content: '';
  position: absolute;
  top: -30px;
  left: 50%;
  width: 2px;
  height: 13px;
  background-color: #ffa500;
}

.level-1,
.level-2,
.level-3,
.level-4,
.level-5,
.level-6,
.level-7,
.level-8,
.level-9,
.level-10,
.level-11,
.level-12,
.level-13,
.level-14,
.level-15,
.level-16,
.level-17,
.level-18,
.level-19,
.level-20 {
  margin: 0 30px;
  position: relative;
}

.level-1::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  width: 2px;
  height: 20px;
  background-color: #ffa500;
}

.level-1:not(:only-child)::after {
  content: '';
  position: absolute;
  top: -20px;
  width: 100%;
  height: 2px;
  background-color: #ffa500;
}

.level-1:first-child:not(:only-child)::after {
  left: 50%;
}

.level-1:last-child:not(:only-child)::after {
  right: 50%;
}

.children:only-child {
  justify-content: center;
}

.level-1:only-child {
  margin: 0;
}

.level-1:only-child::before {
  left: 50%;
}

.level-1:only-child::after {
  display: none;
}

.referral-video-container h2 {
  color: #ffffff;
}

.referral-video-container h1 {
  color: #ffffff;
  text-align: left;
}

.mileston-componant {
  width: 80%;
  height: 100%;
}

@media (max-width: 2258px) {
  .back-button {
    left: 250px;
  }
}

@media (max-width: 1480px) {
  .back-button {
    left: 230px;
  }
}

@media (max-width: 1105px) {
  .back-button {
    left: 250px;
  }
}

@media (max-width: 1031px) {
  .back-button {
    left: 10px;
  }
}

/* @media (max-width: 2260px) {
  .back-button {
    top: 15%;
    left: 20%;
  }
 }

 @media (max-width: 1700px) {
  .back-button {
    top: 120px;
    top: 14%;
  }
 }

 @media (max-width: 1200px) {
  .back-button {
    top: 120px;
    left: 25%;
  }
  .mileston-container-top {
    display: inline;
  }
}

@media (max-width: 1120px) {
  .back-button {
    top: 15%;
    left: 25%;
  }
  .mileston-container-top {
    display: inline;
  }
}

@media (max-width: 1031px) {
  .back-button {
    top: 120px;
    left: 20px;
  }
  .referral-tree-container {
    margin-top: 10%;
  }
}

@media (max-width: 910px) {
  .referral-tree-container {
    margin-top: 12%;
  }
  .referral-tree-container2 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 830px) {
  .referral-tree-container2 {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}

@media (max-width: 790px) {
  .referral-tree-container {
    margin-top: 120px;
  }
}

@media (max-width: 670px) {
  .referral-tree-container2 {
    margin-top: 130px;
    margin-left: 20px;
  }
}

@media (max-width: 570px) {
  .referral-tree-container2 {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 25px;
  }
}

@media (max-width: 526px) {
  .referral-tree-container {
    margin-top: 25%;
  }
  .referral-video-container {
    max-width: 500px;
    margin-left: 1px;
    margin-right: 1px;
  }
  .referral-tree-container2 {
    padding: 20px;
    max-width: 450px;
  }
}

@media (max-width: 470px) {
  .referral-tree-container {
    margin-top: 27%;
  }
}

@media (max-width: 370px) {
  .referral-tree-container {
    margin-top: 30%;
  }
  .referral-tree-container2 {
    padding: 15px;
    max-width: 320px;
  }
} */