.video-tran-container {
    margin-top: 10%;
}

.transection-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px;
}

.left-dashboard {
    grid-column: span 2;
}

.right-dashboard {
    grid-column: span 1;
}

.left-container {
    padding: 20px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.earnings-card,
.transactions-card,
.team-card {
    background-color: #242424;
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 24px;
}

.earnings-header,
.transactions-header,
.team-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.earnings-header h1,
.transactions-header h1,
.team-header h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.amount {
    color: #4ADE80;
    font-size: 20px;
    font-weight: bold;
}

.more-options {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
}

.transactions-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 16px;
    border-radius: 8px;
}

.transaction-left {
    display: flex;
    align-items: center;
    gap: 16px;
}

.icon {
    color: #fff;
    width: 40px !important;
    height: 40px !important;
    border-radius: 8px !important;
    background-color: #4B5563 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 20px !important;
}

.transaction-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.transaction-id {
    font-family: monospace;
    font-size: 14px;
}

.transaction-time {
    color: #9CA3AF;
    font-size: 14px;
}

.transaction-right {
    display: flex;
    align-items: center;
    gap: 24px;
}

.transaction-details {
    text-align: right;
}

.transaction-type {
    font-size: 14px;
}

.transaction-level {
    color: #9CA3AF;
    font-size: 14px;
}

.transaction-amount {
    color: #4ADE80;
    font-size: 16px;
    font-weight: bold;
    min-width: 80px;
    text-align: right;
}

.team-members {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding-bottom: 8px;
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.member-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.member-name {
    font-size: 14px;
    color: #9CA3AF;
}

/* Scrollbar Styling */
.team-members::-webkit-scrollbar {
    height: 4px;
}

.team-members::-webkit-scrollbar-track {
    background: #374151;
    border-radius: 2px;
}

.team-members::-webkit-scrollbar-thumb {
    background: #4B5563;
    border-radius: 2px;
}

.sidebar {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px;
}

.membership-card {
    background-color: #6D28D9;
    border-radius: 16px;
    padding: 24px;
    color: white;
}

.upgrade-button {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
}

.membership-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.membership-header h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    cursor: pointer;
}

.star {
    font-size: 24px;
}

.membership-card h2 {
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 16px 0;
    letter-spacing: 1px;
}

.membership-card p {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 16px 0;
    color: rgba(255, 255, 255, 0.9);
}

.arrows {
    color: #f0ff21;
    font-size: 40px;
    letter-spacing: 2px;
}

.my-card-info {
    background-color: #242424;
    border-radius: 16px;
    padding: 24px;
    color: white;
}

.my-card-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.card-details {
    background-color: transparent;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.info-label {
    color: #f4f4f4;
    font-size: 16px;
}

.info-value {
    font-size: 16px;
    text-align: right;
}

.add-node-button {
    background-color: #6D28D9;
    color: white;
    border: none;
    border-radius: 12px;
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.add-node-button:hover {
    background-color: #5B21B6;
}

/* Add this to your Transactions.css file */

.my-card-info {
  perspective: 1000px;
  width: 100%;
  padding: 20px;
}

.card-container {
  position: relative;
  transition: transform 0.3s;
  transform-style: preserve-3d;
  min-height: 300px;
}

.card-container.flipping {
  transform: rotateY(90deg);
}

.card-face {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Card background gradients */
.fan-pass {
  background: linear-gradient(135deg, #4a90e2, #8e44ad);
}

.bitcoin-explorer {
  background: linear-gradient(135deg, #f1c40f, #e67e22);
}

.metaverse-master {
  background: linear-gradient(135deg, #2ecc71, #3498db);
}

.blockchain-pro {
  background: linear-gradient(135deg, #e74c3c, #e91e63);
}

.ai-ultimate {
  background: linear-gradient(135deg, #6c3483, #9b59b6);
}

.default-card {
  background: linear-gradient(135deg, #2c3e50, #34495e);
}

.card-content {
  position: relative;
  z-index: 2;
}

/* Card decoration elements */
.card-decoration-circle {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.card-decoration-lines {
  position: absolute;
  bottom: -20px;
  left: -20px;
  width: 150px;
  height: 150px;
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 0px,
    rgba(255, 255, 255, 0.1) 10px,
    transparent 10px,
    transparent 20px
  );
  transform: rotate(45deg);
  z-index: 1;
}

.info-row {
  padding: 8px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: opacity 0.15s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flipping .info-row {
  opacity: 0;
}

.info-value {
  color: white;
  font-weight: 600;
}

.card-face::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 50%,
    transparent 100%
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  20% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.my-card-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 1360px) {
    .video-tran-container {
        margin-top: 120px;
    }
}

@media (max-width: 850px) {
    .video-tran-container {
        margin-top: 15%;
    }
}

@media (max-width: 790px) {
    .transection-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
    }
    .right-dashboard {
        grid-column: span 2;
    }
    .sidebar {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }
}

@media (max-width: 695px) {
    .video-tran-container {
        margin-top: 15%;
        grid-template-columns: repeat(1, 1fr);
    }

    .sidebar {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 650px) {
    .video-tran-container {
        margin-top: 20%;
    }
}

@media (max-width: 530px) {
    .video-tran-container {
        margin-top: 25%;
    }
}

@media (max-width: 590px)  {
    .transaction-amount {
        min-width: 40px;
    }
    .transactions-card {
        width: 100%;
    }
 }

@media (max-width: 485px) {
    .video-tran-container {
        margin-top: 27%;
    }
}

@media (max-width: 415px) {
    .video-tran-container {
        margin-top: 29%;
    }
    .transaction-amount {
        min-width: 0px;
    }
}

@media (max-width: 375px) {
    .video-tran-container {
        margin-top: 30%;
    }
}

@media (max-width: 335px) {
    .video-tran-container {
        margin-top: 33%;
    }
}

@media (max-width: 317px) {
    .video-tran-container {
        margin-top: 120px;
    }
}