.membership {
  background-color: #404040;
  color: #ffffff;
  max-width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 30px;
}

.membership h2 {
  margin-bottom: 20px;
  color: #fff;
}

.membership-content {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.no-membership {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  display: block;
  margin-bottom: 10px;
}

.vip-membership-card {
  background-color: transparent;
  color: #ffffff;
  max-width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: rgb(0, 0, 0);
}

.vip-membership-card h2 {
  color: #ffffff;
}

.get-membership {
  color: #fff;
}

.join-membership {
  margin-top: 24px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: transparent;
  transition: transform .2s;
  border: 2px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.join-membership:hover {
  transform: scale(1.05);
}

.nft-item {
  background-color: transparent;
  color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nft-item h3 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.nft-item img,
.nft-item video {
  color: #fff;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
}

.nft-item p {
  color: #ffffff;
  line-height: 1.5;
}

.traits-list {
  color: #fff;
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.traits-list li {
  background: linear-gradient(45deg, #89a6ff, #7e7e7e);
  border-radius: 4px;
  padding: 5px 10px;
  margin: 5px 0;
  font-size: 14px;
}

.vip-cards-container {
  padding: 20px;
}

.vip-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.vip-card {
  background-color: #c0025e;
  border-radius: 10px;
  padding: 20px;
  width: 22%;
  text-align: center;
}

.vip-card h3 {
  margin-top: 0;
}

.price {
  font-size: 2rem;
  margin: 1rem 0;
}

.view-cards {
  background-color: #c0025e;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.features {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.features li {
  margin-bottom: 5px;
}

.vip-card:nth-child(even) {
  background-color: #c0025e;
}

@media (max-width: 768px) {
  .membership-content {
    grid-template-columns: 1fr; /* Change grid layout to single column for memberships */
    gap: 10px; /* Adjust gap for smaller screens */
  }

  .nft-item {
    padding: 10px; /* Adjust padding for NFT items */
  }
}