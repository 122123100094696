/* styles/Header.css */
.header {
  /* background: linear-gradient(45deg, #000000, #30115b); */
  background-color: #1f1f1f;
  color: rgb(255, 255, 255);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 0px  #717171;
  align-items: center;
  position: fixed;
  height: 100px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.header-right {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 1rem;
  margin-left: 30px;
}

.header-right {
  display: flex;
  align-items: center;
}

.iekbcc9 {
  background-color: transparent !important;
  color: #ffffff;
  padding: 8px 16px;
  transition: transform .2s;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  font-size: 2rem;
}

@media (max-width: 453px) {
  .logo {
    height: 30px;
    margin-right: 0px;
    margin-left: 20px;
  }
}

@media (max-width: 380px) {
  .logo {
    height: 25px;
    margin-right: 0px;
    margin-left: 15px;
  }
}