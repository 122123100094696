.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 8%;
}

.post-grid {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  width: 100%;
}

.title-currencies {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time-title {
  display: flex;
}

.news-time {
  margin-right: 10px;
}

.post-card {
  color: #fff;
}

.title {
  font-size: 15px;
  margin-bottom: 5px;
}

.news-time {
  color: #5b5b5b;
  font-size: 13px;
}

.source {
  font-size: 14px;
  color: #888;
  cursor: pointer;
  margin-left: 30px;
  max-width: 200px;
}

.currencies {
  margin: 10px auto;
  margin-right: 0px;
}

.currency {
  color: #ff1e96;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 5px;
}

.votes {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
}

.read-more {
  color: #1e90ff;
  text-decoration: none;
}

@media (max-width: 1380px) {
  .excerpt img {
    max-width: 600px;
  }
}

@media (max-width: 1300px) {
  .excerpt img {
    max-width: 580px;
  }
}

@media (max-width: 1200px) {
  .excerpt img {
    max-width: 540px;
  }
}

@media (max-width: 1125px) {
  .excerpt img {
    max-width: 500px;
  }
}

@media (max-width: 1025px) {
  .excerpt img {
    max-width: 400px;
  }
  .container {
    margin-top: 6%;
  }

  .post-card .h1-text, title, .p-text {
    margin-left: 18%;
    margin-right: 18%;
  }
  
  .title {
    font-size: 15px;
  }
}

@media (max-width: 910px) {
  .excerpt img {
    max-width: 340px;
  }

  .container {
    margin-top: 7%;
  }

  .post-card .h1-text, title, .p-text {
    margin-left: 16%;
    margin-right: 16%;
  }
}

@media (max-width: 850px) {
  .excerpt img {
    max-width: 450px;
  }
}

@media (max-width: 790px) {
  .container {
    margin-top: 8%;
  }

  .post-card .h1-text, title, .p-text {
    margin-left: 14%;
    margin-right: 14%;
  }
}

@media (max-width: 610px) {
  .excerpt img {
    max-width: 420px;
  }
}

@media (max-width: 670px) {
  .container {
    margin-top: 10%;
  }
  .title,
  .currency {
    font-size: 14px;
  }
}

@media (max-width: 570px) {
  .container {
    margin-top: 11%;
  } 
  .excerpt img {
    max-width: 380px;
  }
}

@media (max-width: 550px) {
  .container {
    margin-top: 13%;
  }
  .excerpt img {
    max-width: 360px;
  }
  .title,
  .currency {
    font-size: 13px;
  }
}

@media (max-width: 515px) {
  .container {
    margin-top: 17%;
  }
  .excerpt img {
    max-width: 320px;
  }
  .container {
    margin-top: 12%;
  }
}
@media (max-width: 500px) {
  .container {
    margin-top: 18%;
  }
}

@media (max-width: 470px) {
  .excerpt img {
    max-width: 280px;
  }
}

@media (max-width: 437px) {
  .container {
    margin-top: 20%;
  }
  .excerpt img {
    max-width: 220px;
  }
  .title,
  .currency {
    font-size: 12px;
  }
}

@media (max-width: 420px) {
  .container {
    margin-top: 21%;
  }
  .currency {
    margin-left: 1px;
    margin-right: 1px;
  }
  .title,
  .currency {
    font-size: 11px;
  }
}

@media (max-width: 370px) {

  .excerpt img {
    max-width: 180px;
  }
  .title,
  .currency {
    font-size: 10px;
  }
}
