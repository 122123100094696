.product-promotion {
  background-color: transparent;
  max-width: 500px;
  margin: 100px auto;
  margin-top: 15%;
  padding: 20px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px #0000;
}

.product-promotion h2 {
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.banner-size {
  color: #ebff52;
}

button[type="submit"] {
  background-color: #6c2bd9;
  display: block;
  width: 100%;
  padding: 10px;
  color: white;
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.banner-preview {
  display: block;
  margin: auto;
}

.banner-image {
  width: 120px;
  height: 50px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  color: #333;
  appearance: none;
  cursor: pointer;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23000" d="M2 0L0 2h4z"/></svg>');
  /* Custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}

.form-group select:focus {
  outline: none;
  border-color: #464646;
  box-shadow: 0 0 5px rgb(61, 66, 71);
}

.promotionDuration {
  color: #ffffff;
}

.form-group label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #ffffff;
}

.form-group select {
  background-color: #1f1f1f;
  color: #ffffff;
}

.select-theme {
  background-color: #1f1f1f;
  color: white;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
}

.select-theme option {
  background-color: #1f1f1f;
  color: white;
}

@media (max-width: 1090px) {
  .product-promotion {
    margin-top: 17%;
  }
}

@media (max-width: 975px) {
  .product-promotion {
    margin-top: 19%;
  }
}

@media (max-width: 855px) {
  .product-promotion {
    margin-top: 21%;
  }
}

@media (max-width: 600px) {
  .product-promotion {
    margin-top: 23%;
  }
}

@media (max-width: 600px) {
  .product-promotion {
    margin-top: 25%;
    width: 95%;
  }
}

@media (max-width: 460px) {
  .product-promotion {
    margin-top: 27%;
  }
}

@media (max-width: 460px) {
  .product-promotion {
    margin-top: 30%;
  }
}

@media (max-width: 360px) {
  .product-promotion {
    margin-top: 35%;
  }
}

@media (max-width: 315px) {
  .product-promotion {
    margin-top: 38%;
  }
}