.contact-form {
  color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, sans-serif;
  margin-top: 0;
}

.header-text {
  background-color: #1f1f1f;
  color: #ffffff;
  padding: 20px;
  margin-top: 8%;
  margin-bottom: 20px;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.address {
  color: #ffffff;
  margin-bottom: 5%;
  font-weight: bold;
}

.logo-image {
  margin-bottom: 5%;
}

.address h1 {
  color: #ffffff;
  font-weight: bold;
  text-align: left;
}

.address h1, p {
  color: #c0025e;
}

.emai-para,
.web-para {
  color: #ffffff;
  cursor: pointer;
}

.contact-info {
  color: #ffffff;
  flex: 1;
}

.contact-info p {
  color: #ffffff;
  margin: 10px 0;
}

.form-container {
  color: #ffffff;
  flex: 1;
  margin-left: 40px;
}

.form-group {
  color: #ffffff;
  margin-bottom: 20px;
}

.form-group label {
  color: #ffffff;
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  color: #ffffff;
  background-color: transparent;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  color: #ffffff;
  height: 100px;
}

.send-message {
  color: #ffffff;
  text-align: center;
}

.send-message button {
  background-color: #c0025e;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.send-message button:hover {
  background-color: #ff007b;
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.round-icon {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 120px;
  cursor: pointer;
}

.icon-logo {
  background-color: #1f1f1f;
  color: #ffffff;
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 768px) {
  .contact-form {
    display: inline-block;
    justify-content: center;
  }

  .form-container {
    margin-top: 30px;
    margin-left: auto;
  }

  .header-text {
    margin-top: 22%;
  }
}

@media (max-width: 1031px) {
  .header-text {
    margin-top: 10%;
  }
}

@media (max-width: 910px) {
  .header-text {
    margin-top: 12%;
  }
}

@media (max-width: 790px) {
  .header-text {
    margin-top: 14%;
  }
}

@media (max-width: 670px) {
  .header-text {
    margin-top: 16%;
  }
}

@media (max-width: 570px) {
  .header-text {
    margin-top: 18%;
  }
}

@media (max-width: 470px) {
  .header-text {
    margin-top: 20%;
  }
}

@media (max-width: 370px) {
  .header-text {
    margin-top: 22%;
  }
}