.terms-container {
  background-color: #1f1f1f;
  color: #ffffff;
  padding: 20px;
  margin-top: 9%;
  margin-bottom: 20px;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #ffffff;
  text-align: center;
}

h2 {
  color: #ffffff;
  border-bottom: 2px solid #909090;
  padding-bottom: 10px;
}

h3 {
  color: #ffffff;
  margin-top: 30px;
}

p {
  margin-bottom: 15px;
  color: #ffffff;
}

.acceptance {
  background-color: #2a2a2a;
  padding: 15px;
  border-radius: 5px;
  font-weight: bold;
}

.company-info {
  background-color: #2a2a2a;
  color: #ffffff;
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;
}

.company-info p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .terms-container {
    margin-top: 22%;
  }
}

@media (max-width: 1031px) {
  .terms-container {
    margin-top: 10%;
  }
}

@media (max-width: 910px) {
  .terms-container {
    margin-top: 12%;
  }
}

@media (max-width: 790px) {
  .terms-container {
    margin-top: 14%;
  }
}

@media (max-width: 670px) {
  .terms-container {
    margin-top: 16%;
  }
}

@media (max-width: 570px) {
  .terms-container {
    margin-top: 18%;
    font-size: 15px;
  }
}

@media (max-width: 470px) {
  .terms-container {
    margin-top: 20%;
    font-size: 12px;
  }
}

@media (max-width: 390px) {
  .terms-container {
    font-size: 10px;
  }
}

@media (max-width: 370px) {
  .terms-container {
    margin-top: 22%;
  }
}