.sidebar-container {
  margin-top: 7%;
  left: 0;
  height: 100%;
  z-index: 900;
  transition: transform 0.3s ease-in-out;
}

.sidebar {
  background-color: #1f1f1f;
  color: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  border-radius: 10px;
}

.bottom-item {
  display: flex;
  width: 200px;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  border-radius: 10px;
  position: fixed;
  bottom: 10px;
}

.sidebar-item.active {
  color: #ff0088;
  font-weight: bold;
  border-radius: 10px;
}

.bottom-item.active {
  color: #ff0088;
  font-weight: bold;
  border-radius: 10px;
}

.sidebar-item:hover {
  background-color: #343434;
  color: #ff1692;
  border-radius: 10px;
}

.bottom-item:hover {
  background-color: #343434;
  color: #ff2097;
  border-radius: 10px;
}

.sidebar-item i {
  margin-right: 10px;
  width: 20px;
  text-align: center;
}

.bottom-item i {
  margin-right: 10px;
  width: 20px;
  text-align: center;
}

@media (max-width: 1130px) {
  .sidebar-container {
    margin-top: 8%;
  }
}

@media (max-width: 1030px) {
  .sidebar-container {
    margin-top: 9%;
  }
}

@media (max-width: 930px) {
  .sidebar-container {
    margin-top: 10%;
  }
}

@media (max-width: 868px) {
  .sidebar-container {
    margin-top: 11%;
  }
  .sidebar {
    width: 200px;
  }
}