.page-container {
  background-color: #1f1f1f;
  color: #ffffff;
  max-width: 100%;
  padding: 20px;
  margin-top: 9%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-tabs {
  color: #ffffff;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.nav-tabs a {
  text-decoration: none;
  color: #ffffff;
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

.nav-tabs a.active {
  color: #dcdcdc;
  border-bottom: 2px solid #ff4aa4;
}

.section-title {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
}

.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.video-card {
  color: #ffffff;
  width: 100%;
  border: 1px solid #353535;
  border-radius: 8px;
  padding: 20px;
  margin-top: 5%;
  margin-bottom: 20px;
}

.video-wrapper {
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}

.impactx-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100px;
  z-index: 2;
  pointer-events: none;
}

.video-player {
  width: 100%;
  height: auto;
  z-index: 1;
}

.video-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.video-description {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
}

.academy-text {
  font-size: 14px;
  color: #ff1f8f;
  text-decoration: none;
}

.academy-text:hover {
  text-decoration: underline;
}

/* Media Queries for Responsive Design */
@media screen and (min-width: 768px) {
  .video-card {
    width: 48%;
  }
}

@media screen and (max-width: 767px) {
  .page-container {
    margin-top: 15%;
  }

  .page-container {
    margin-left: 1%;
    margin-right: 1%;
    padding: 15px;
  }

  .nav-tabs a {
    font-size: 16px;
    margin-right: 15px;
  }

  .section-title {
    font-size: 20px;
  }

  .video-title {
    font-size: 16px;
  }

  .video-description {
    font-size: 12px;
  }

  .academy-text {
    font-size: 12px;
  }

  .impactx-logo {
    width: 80px;
  }
}

@media screen and (max-width: 480px) {
  .page-container {
    margin-top: 22%;
  }

  .nav-tabs {
    flex-direction: column;
  }

  .nav-tabs a {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .impactx-logo {
    width: 60px;
  }
}

@media (max-width: 1031px) {
  .page-container {
    margin-top: 10%;
  }
}

@media (max-width: 910px) {
  .page-container {
    margin-top: 12%;
  }
}

@media (max-width: 790px) {
  .page-container {
    margin-top: 14%;
  }
}

@media (max-width: 670px) {
  .page-container {
    margin-top: 16%;
  }
}

@media (max-width: 570px) {
  .page-container {
    margin-top: 18%;
  }
}

@media (max-width: 470px) {
  .page-container {
    margin-top: 20%;
  }
}

@media (max-width: 370px) {
  .page-container {
    margin-top: 22%;
  }
}