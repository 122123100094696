.nft-listing-container {
  max-width: 1200px;
  margin: 100px auto;
  padding: 20px;
  color: #ffffff !important;
}

.nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.nft-price,
.nft-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.nft-total,
.nft-value {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.nft-feature,
.nft-total,
.nft-value {
    font-size: 20px;
    font-weight: bold;
}

.right-tick {
    color: #12ff42;
    font-size: 20px;
    font-weight: bold;
}

.nft-balance {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
}

.fan-balance,
.fan-value {
    color: #f3ff06;
    font-size: 20px;
    font-weight: bold;
}

.bte-balance,
.bte-value {
    color: #ffae00;
    font-size: 20px;
    font-weight: bold;
}

.blp-balance,
.blp-value {
    color: #05c5ff;
    font-size: 20px;
    font-weight: bold;
}

.mvm-balance,
.mvm-value {
    color: #ff05e2;
    font-size: 20px;
    font-weight: bold;
}

.ail-balance,
.ail-value {
    color: #26ff05;
    font-size: 20px;
    font-weight: bold;
}

.nft-info {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.nft-card {
  background-color: transparent !important;
  color: #ffffff;
  border: 1px solid #535353 !important;
  border-radius: 8px !important;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.nft-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
}

.card-container {
    margin: 20px;
}

@media (max-width: 768px) {
  .nft-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}