@keyframes progress {
  0% {
    --percentage: 0;
  }

  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.milestone-rewards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  background-color: #404040;
  backdrop-filter: blur(7.5px);
  color: #fff;
  padding: 20px;
  border-radius: 30px;
  margin-bottom: 20px;
}

.progress-container {
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #ca217e;
  --secondary: rgba(42, 42, 42, 0.68);
  --size: 200px;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(from 0.75turn at 50% 100%, var(--primary) calc(var(--percentage) * 1% / 2), var(--secondary) calc(var(--percentage) * 1% / 2 + 0.1%));
  mask: radial-gradient(at 50% 100%, rgb(75, 243, 255) 55%, transparent 55.5%);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
  -webkit-mask-mode: alpha;
}

.current-amount {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  font-weight: bold;
  color: #00FFD1;
}

.milestone-text {
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  color: #00FFD1;
}

.milestone-list {
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
}

.milestone-item {
  background-color: #343434;
  transition: width 0.5s ease-in-out;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 0px  #252525;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.milestone-item:hover {
  transform: scale(1.05);
  color: #fff;
}

.milestone-name {
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 5px;
}

.milestone-memberships {
  font-size: 20px;
  color: #ffffff;
  margin-left: auto;
}

@media (max-width: 768px) {
  .milestone-rewards {
    grid-template-columns: 1fr; /* Change grid layout to single column */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  [role="progressbar"] {
    --size: 150px; /* Adjust progress bar size for smaller screens */
  }

  .milestone-list {
    grid-template-columns: 1fr; /* Adjust grid layout for milestones */
    gap: 10px; /* Adjust gap for smaller screens */
  }

  .milestone-item {
    padding: 10px; /* Adjust padding for milestone items */
  }

  .milestone-name,
  .milestone-memberships {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
