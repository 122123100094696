.service-dapp-container {
    margin-top: 120px;
}

.available-service-dapp,
.all-service,
.all-dapp {
    margin: 1px;
}

.section-lavel {
    text-align: left;
}

.dapp-image {
    height: 200px;
}

.launch-app {
    padding: 12px;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    background-color: #6c2bd9;
    transition: background-color 0.2s;
}